import React, { useState } from "react";
import { Form } from "react-bootstrap"; // Make sure Form is imported from 'react-bootstrap'

// Import FontAwesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane, faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
// Assuming other necessary imports remain the same...
import "./PaycableTV.css"; // Make sure your CSS is properly linked
import { Breadcrumbs } from "../../AbstractElements";
const PayCableTV = () => {
  const today = new Date().toISOString().split("T")[0];
  const data1 = ["DSTV1", "DSTV2", "DSTV3", "DSTV4"];
  const data2 = [
    "DSTV Smart Card Number1",
    "DSTV Smart Card Number2",
    "DSTV Smart Card Number3",
    "DSTV Smart Card Number4",
  ];
  const data3 = [
    "Select Package1",
    "Select Package2",
    "Select Package3",
    "Select Package4",
  ];
  // State for managing selected locations and fruit
  const [selectedFrom, setSelectedFrom] = useState(data1[0]);
  const [selectedDestination, setSelectedDestination] = useState(data2[0]);
  const [selectedPackage, setSelectedPackage] = useState(data2[0]);
  const [selectedFruit, setSelectedFruit] = useState("");

  // Handlers for selection changes
  const handleFromChange = (event) => {
    setSelectedFrom(event.target.value);
  };

  const handleDestinationChange = (event) => {
    setSelectedDestination(event.target.value);
  };

  const handleSelectChange = (event) => {
    setSelectedFruit(event.target.value);
  };

  return (
    <>
      <Breadcrumbs
        mainTitle="Pay Cable TV"
        parent="Pay Cable TV"
        title="Cable TV"
      />
      <div className="row">
        <div className="PaycableTV_content">
          <div className="row">
            <div className="buy_PaycableTV_content">
              <Form>
                {/* From Location Selector */}
                <Form.Group controlId="fromLocationSelect" className="mb-3">
                  <Form.Select
                    value={selectedFrom}
                    onChange={handleFromChange}
                    className="select-background custom-select-color"
                  >
                    {data1.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                {/* Destination Location Selector */}
                <Form.Group
                  controlId="destinationLocationSelect"
                  className="mb-3"
                >
                  <Form.Select
                    value={selectedFrom}
                    onChange={handleFromChange}
                    className="select-background custom-select-color_g"
                  >
                    {data2.map((data2, index) => (
                      <option key={index} value={data2}>
                        {data2}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                {/* Selected Package Location Selector */}
                <Form.Group
                  controlId="destinationLocationSelect"
                  className="mb-3"
                >
                  <Form.Select
                    value={selectedFrom}
                    onChange={handleFromChange}
                    className="select-background custom-select-color_g"
                  >
                    {data3.map((data3, index) => (
                      <option key={index} value={data3}>
                        {data3}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Form>
              <input className="airtime_input" placeholder="NGN,12,500"></input>
              <div className="save_beneficiary">
                <label class="switch">
                  <input type="checkbox" />
                  <span class="slider round"></span>
                </label>
                <span className="beneficiary_label">Save Beneficiary</span>
              </div>
              <button
                className="PaycableTV_btn"
                onClick={() => alert("Search clicked")}
              >
                <strong>Pay</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayCableTV;
