import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FileText, LogIn, Mail, User } from "react-feather";
import man from "../../../assets/images/dashboard/profile.png";

import { LI, UL, Image, P } from "../../../AbstractElements";

import CustomizerContext from "../../../_helper/Customizer";
import { Account, Admin, Inbox, LogOut, Taskboard } from "../../../Constant";
import UserContext from "../../../_helper/User";

const UserHeader = () => {
  const navigate = useNavigate();
  const { userInfo } = useContext(UserContext);

  // Function to handle logout
  const Logout = () => {
    localStorage.removeItem("profileURL");
    localStorage.removeItem("token");
    localStorage.removeItem("auth0_profile");
    localStorage.removeItem("Name");
    localStorage.setItem("authenticated", false);
    navigate("/home");
  };
  // Initialize state for the image source
  const [imageSrc, setImageSrc] = useState(userInfo.profileImageUrl || man);
  // Function to handle redirection
  const UserMenuRedirect = (redirect) => {
    navigate(redirect);
  };

  // useEffect hook to handle updates when userInfo changes
  useEffect(() => {
    // Update the image source when userInfo changes
    setImageSrc(userInfo.profileImageUrl || man);

    // This is a placeholder for any action you'd like to take when userInfo changes.
    console.log("User info has changed:", userInfo);
  }, [userInfo]); // Passing userInfo as a dependency

  return (
    <li className="profile-nav onhover-dropdown pe-0 py-0">
      <div className="media profile-media">
        <div className="media-body">
          <span>{userInfo.userName}</span>
          <P attrPara={{ className: "mb-0 font-roboto" }}>
            {} <i className="middle fa fa-angle-down"></i>
          </P>
        </div>
        <Image
          attrImage={{
            className: "b-r-10",
            src: userInfo.profileImageUrl || man,
            alt: "",
            style: { width: "32px", height: "32px" }, // Inline style for size
          }}
        />
      </div>
      <UL
        attrUL={{ className: "simple-list profile-dropdown onhover-show-div" }}
      >
        <LI attrLI={{ onClick: () => UserMenuRedirect(`/account`) }}>
          <User />
          <span>{Account} </span>
        </LI>
        <LI attrLI={{ onClick: () => UserMenuRedirect(`/dashboard`) }}>
          <Mail />
          <span>{Inbox}</span>
        </LI>
        <LI attrLI={{ onClick: Logout }}>
          <LogIn />
          <span>{LogOut}</span>
        </LI>
      </UL>
    </li>
  );
};

export default UserHeader;
