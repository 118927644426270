import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { H5, H4, H6, P, Image, Btn } from "../../AbstractElements";
import { Link } from "react-router-dom";
import {
  MyProfile,
  Bio,
  Password,
  UserID,
  Website,
  Save,
  EmailAddress,
} from "../../Constant";
import CustomizerContext from "../../_helper/Customizer";
import UserContext from "../../_helper/User";
import { updateProfileAPI } from "../../Library/apis";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
const MyProfileEdit = () => {
  const { handleSubmit } = useForm();
  const { layoutURL } = useContext(CustomizerContext);
  const { userInfo } = useContext(UserContext);
  const [url, setUrl] = useState(userInfo.profileImageUrl);
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    profileImageUrl: "",
  });
  const readUrl = (event) => {
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      userInfo.profileImageUrl = reader.result;
      setFormValues({ ...formValues, ["profileImageUrl"]: reader.result });
      setUrl(reader.result);
    };
  };
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const onUpdateSubmit = () => {
    // setIsUpdating(true);
    updateProfileAPI(formValues)
      .then((res) => {
        toast.success(res.message, { autoClose: 2000 });

        // setIsUpdating(false);
      })
      .catch((error) => {
        const message =
          error?.response?.data?.message || "Update Profile Error";
        toast.error(message, { autoClose: 2000 });
        // setIsUpdating(false);
      });
  };
  useEffect(() => {
    console.log(userInfo.profileImageUrl);
    if (userInfo) {
      console.log(userInfo.profileImageUrl);
      setFormValues({
        firstName: userInfo.firstName || "",
        lastName: userInfo.lastName || "",
        phoneNumber: userInfo.phoneNumber || "",
        profileImageUrl: userInfo.profileImageUrl || "",
        email: userInfo.email || "",
      });
    }
  }, [userInfo]);
  return (
    <Fragment>
      <ToastContainer />
      <div className="user-profile">
        <Card className="hovercard">
          <CardHeader>
            <H4 attrH4={{ className: "card-title mb-0" }}>{MyProfile}</H4>
            <div className="card-options">
              <a className="card-options-collapse" href="#javascript">
                <i className="fe fe-chevron-up"></i>
              </a>
              <a className="card-options-remove" href="#javascript">
                <i className="fe fe-x"></i>
              </a>
            </div>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(onUpdateSubmit)}>
              <Row className="mb-2">
                <div className="profile-title">
                  <div className="media">
                    <div className="user-image">
                      <div className="avatar">
                        <Image
                          attrImage={{
                            className: "step1",
                            alt: "",
                            src: `${
                              url
                                ? url
                                : require("../../assets/images/user/7.jpg")
                            }`,
                          }}
                        />
                      </div>
                      <div
                        className="icon-wrapper step2"
                        data-intro="Change Profile image here"
                      >
                        <i
                          className="icofont icofont-pencil-alt-5"
                          onChange={(e) => readUrl(e)}
                        >
                          <input
                            className="upload"
                            type="file"
                            onChange={(e) => readUrl(e)}
                          />
                        </i>
                      </div>
                    </div>
                    <div>
                      <Link to={`/account`}>
                        <H5 attrH5={{ className: "user-name" }}>
                          {userInfo.userName}
                        </H5>
                      </Link>
                    </div>
                  </div>
                </div>
              </Row>
              <FormGroup className="mt-5">
                <Input
                  type="textarea"
                  className="form-control"
                  rows="5"
                  defaultValue="On the other hand, we denounce with righteous indignation"
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label className="form-label">{EmailAddress}</Label>
                <Input
                  className="form-control"
                  placeholder="your-email@domain.com"
                  value={userInfo.email}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label className="form-label">{UserID}</Label>
                <Input
                  className="form-control"
                  placeholder="userid"
                  value={userInfo.userId}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label className="form-label">{Password}</Label>
                <Input
                  className="form-control"
                  type="password"
                  defaultValue="password"
                  onChange={handleChange}
                />
              </FormGroup>
              <div className="form-footer">
                <Btn attrBtn={{ color: "custom", type: "submit" }}>{Save}</Btn>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </Fragment>
  );
};
export default MyProfileEdit;
