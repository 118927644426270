import React from "react";
import virtual from "../../assets/images/Icon/virtual.png";
import virtual_card from "../../assets/images/Icon/virtual_card.png";
import fund_transfer from "../../assets/images/Icon/fund_transfer.png";
import recurring from "../../assets/images/Icon/recurring.png";
import qr_code_scan from "../../assets/images/Icon/qr_code_scan.png";
import fund_betting from "../../assets/images/Icon/fund_betting.png";
import plus from "../../assets/images/Icon/plus.png";

import "./Dashboard.css";
import { Link } from "react-router-dom";

const Dashboard = () => {
  return (
    <>
      <div className="row">
        <div className="col-9 dashboard_content">
          <div className="row">
            <div className="card_item col-4">
              <Link to="/dashboard">
                <div className="dashboard_card">
                  <img src={virtual}></img>
                  <div className="hightlight__text">Virtual Account</div>
                </div>
              </Link>
            </div>
            <div className="card_item col-4">
              <Link to="/dashboard">
                <div className="dashboard_card virtual_card">
                  <div className="card_title">
                    <span>Virtual Card</span>
                    <img src={plus} alt="" />
                  </div>
                  <div className="card_num">1234 1234 1234 1234</div>
                  <div className="card_date">02/25</div>
                </div>
              </Link>
            </div>
            <div className="card_item col-4">
              <Link to="/dashboard">
                <div className="dashboard_card">
                  <img src={fund_transfer}></img>
                  <div className="dashboard_card__text">Fund Transfer</div>
                </div>
              </Link>
            </div>
          </div>

          <div className="row mt-5">
            <div className="card_item col-4">
              <Link to="/dashboard">
                <div className="dashboard_card">
                  <img src={recurring}></img>
                  <div className="dashboard_card__text">Recurring Payment</div>
                </div>
              </Link>
            </div>
            <div className="card_item col-4">
              <Link to="/dashboard">
                <div className="dashboard_card">
                  <img src={qr_code_scan}></img>
                  <div className="dashboard_card__text">
                    QR Code Scan to Pay
                  </div>
                </div>
              </Link>
            </div>
            <div className="card_item col-4">
              <Link to="/dashboard">
                <div className="dashboard_card">
                  <img src={fund_betting}></img>
                  <div className="dashboard_card__text">Fund Betting Agent</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
