import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Btn, H4, H6, P } from "../../../AbstractElements";
import {
  confirmOTPCodeAPI,
  confirmResetPasswordAPI,
  sendVerificationRequestAPI,
} from "../../../Library/apis";

const ForgetPwdMain = () => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [checkOPT, setCheckOPT] = useState(false);

  const [sendButton, setSendButton] = useState("Send");
  const [userInfo, setUserInfo] = useState("");
  const [code, setCode] = useState("");
  const [token, setToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const navigate = useNavigate();

  const handleSend = () => {
    if (!userInfo) {
      toast.error("Enter your email", { autoClose: 2000 });
      return;
    }

    sendVerificationRequestAPI({ userInfo: userInfo })
      .then(() => {
        setTogglePassword(true);
        toast.success("Sent verification code", { autoClose: 2000 });
      })
      .catch((e) => {
        console.log(e);

        toast.error(e?.response?.data?.message || "Send Veirification error", {
          autoClose: 2000,
        });
      });
  };

  const handleConfirmOPTCode = () => {
    if (!userInfo) {
      toast.error("Enter your email", { autoClose: 2000 });
      return;
    }
    if (!code) {
      toast.error("Enter activation code", { autoClose: 2000 });
      return;
    }

    confirmOTPCodeAPI({ userInfo, activationCode: code })
      .then((res) => {
        toast.success(res.message, { autoClose: 2000 });
        setToken(res.token);
        setCheckOPT(true);
      })
      .catch((error) => {
        console.log(error?.response?.data?.message);
        toast.error(
          error?.response?.data?.message || "Code Veirification error",
          { autoClose: 2000 }
        );
      });
  };

  const handleChangePassword = () => {
    confirmResetPasswordAPI({ password: newPassword, token })
      .then((res) => {
        toast.success(res.message, { autoClose: 2000 });
        navigate("/auth/login");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Password Reset error", {
          autoClose: 2000,
        });
      });
  };

  const handleClose = () => {
    navigate("/home");
  };

  return (
    <Fragment>
      <ToastContainer />
      <section>
        <Container className="p-0 login-page" fluid={true}>
          <Row className="m-0">
            <Col className="p-0">
              <div className="login-card">
                <div className="login-main position-relative">
                  <span
                    className="position-absolute"
                    style={{ right: 20, top: 20, cursor: "pointer" }}
                    onClick={handleClose}
                  >
                    <i className="fa fa-times fa-lg"></i>
                  </span>
                  <Form className="theme-form login-form">
                    <H4 attrH4={{ className: "text-warning text-center" }}>
                      Reset Your Password
                    </H4>

                    <FormGroup>
                      <Label className="m-0 col-form-label text-black">
                        Email
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="userInfo"
                        required
                        placeholder="Enter your email"
                        value={userInfo}
                        onChange={(e) => setUserInfo(e.target.value)}
                      />
                    </FormGroup>

                    <FormGroup className="text-end">
                      <Btn
                        attrBtn={{
                          className: "btn-block ",
                          color: "warning",
                          onClick: handleSend,
                        }}
                      >
                        {sendButton}
                      </Btn>
                    </FormGroup>

                    {!checkOPT && (
                      <>
                        <FormGroup>
                          <Label className="text-black">Enter OTP</Label>
                          <Input
                            className="form-control text-right opt-text"
                            placeholder="000000"
                            type="text"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                          />
                        </FormGroup>

                        <FormGroup className="text-end">
                          <Btn
                            attrBtn={{
                              className: "btn-block ",
                              color: "warning",
                              onClick: handleConfirmOPTCode,
                            }}
                          >
                            {"Verify"}
                          </Btn>
                        </FormGroup>
                      </>
                    )}

                    {checkOPT && (
                      <>
                        <H6 attrH6={{ className: "mt-4" }}>
                          Create Your Password
                        </H6>
                        <FormGroup className="position-relative">
                          <Label className="col-form-label m-0 text-black">
                            New Password
                          </Label>
                          <div className="position-relative">
                            <Input
                              className="form-control"
                              type={togglePassword ? "text" : "password"}
                              required
                              placeholder="*********"
                              value={newPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <div
                              className="show-hide text-warning"
                              onClick={() => setTogglePassword(!togglePassword)}
                            >
                              <span
                                className={
                                  togglePassword
                                    ? "text-warning"
                                    : "show text-warning"
                                }
                              ></span>
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <Btn
                            attrBtn={{
                              color: "warning",
                              className: "btn d-block w-100",
                              onClick: handleChangePassword,
                            }}
                          >
                            Done
                          </Btn>
                        </FormGroup>
                      </>
                    )}

                    <P attrPara={{ className: "text-center mb-0 mt-3" }}>
                      Already have an password?
                      <Link className="ms-2 text-warning" to={`/auth/login`}>
                        Log In
                      </Link>
                    </P>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default ForgetPwdMain;
