import React from "react";
import Discover from "../LandingPage/Discover";
import Services from "../LandingPage/Services";
import About from "../LandingPage/About";
import WhatWeDo from "../LandingPage/WhatWeDo";
import DownloadApp from "../LandingPage/DownloadApp";
import MoreFeatures from "../LandingPage/MoreFeatures";
import Testimonials from "../LandingPage/Testimonials";
import JoinUs from "../LandingPage/JoinUs";
import Navbar from "../Navbar";
import Footer from "../Footer";

const LandingPage = () => {
  return (
    <>
      <Navbar />
      <div className="App container-fluid">
        <div className="content">
          <Discover />
          <Services />
          <About />
          <WhatWeDo />
          <DownloadApp />
          <MoreFeatures />
          <Testimonials />
          <JoinUs />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LandingPage;
