import React, { Fragment, useState, useEffect } from "react";
import { LoaderStyle } from "../../Constant";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Breadcrumbs, H6, Spinner } from "../../AbstractElements";

const Loader = (props) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [show]);

  return (
    <Fragment>
      <div className={`loader-wrapper ${show ? "" : "loderhide"}`}>
        <Col md="3">
          <div className="loader-box">
            <Spinner attrSpinner={{ className: "loader-7" }} />
          </div>
        </Col>
      </div>
    </Fragment>
  );
};

export default Loader;
