import React from "react";
import "../LandingPage/LandingPage.css";
import CarbonMobile from "../../assets/images/svg/carbon_mobile.svg";
import CarbonFlight from "../../assets/images/svg/carbon_flight-schedule.svg";
import BillPayment from "../../assets/images/svg/bill_payment.svg";
import PajamaMobile from "../../assets/images/svg/pajamas_mobile.svg";
import CarbonTV from "../../assets/images/svg/Tv.svg";

const Services = () => {
  return (
    <>
      <section className="service__page">
        <h3 className="service__heading text-center">SERVICES</h3>
        <h3 className="service__subheading text-center">
          Explore Our Top Service Offerings
        </h3>
        <div className="service">
          <div className="single__service">
            <img src={CarbonMobile} alt="Carbon mobile" />
            <p className="service__label">Airtime Purchase</p>
          </div>
          <div className="single__service">
            <div className="icon__heighlight">
              <img src={CarbonFlight} alt="Carbon Flight" />
            </div>
            <p className="service__label">Flight Bookings</p>
          </div>
          <div className="single__service">
            <div className="icon__heighlight">
              <img src={BillPayment} alt="Bill Payment" />
            </div>
            <p className="service__label">Bill Payment</p>
          </div>
          <div className="single__service">
            <div className="icon__heighlight">
              <img src={PajamaMobile} alt="pajama Mobile" />
            </div>
            <p className="service__label">Buy Data</p>
          </div>
          <div className="single__service">
            <div className="icon__heighlight">
              <img src={CarbonTV} alt="CarbonTV" />
            </div>
            <p className="service__label">
              Pay Cable TV
              <br /> Subscription
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
