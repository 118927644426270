import React from "react";
import mtn from "../../assets/images/Icon/mtn.png";
import glo from "../../assets/images/Icon/glo.png";
import airtel from "../../assets/images/Icon/airtel.png";
import mobile from "../../assets/images/Icon/mobile.png";

import "./Airtime.css";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "../../AbstractElements";

const Airtime = () => {
  return (
    <>
      <Breadcrumbs
        mainTitle="Buy Airtime & Data"
        parent="Airtime"
        title="Buy"
      />
      <div className="row">
        <div className="airtime_content">
          <div className="row">
            <div className="buy_airtime_content">
              <div className="airtime_header">
                <div className="airtime_header_text"></div>
                <button className="airtime_header_btn">Buy Data</button>
              </div>
              <div className="choose_provider mt-3">
                <p className="provider_text">Choose Provider</p>
                <div>
                  <img src={mtn} className="provider_img"></img>
                  <img src={glo} className="provider_img"></img>
                  <img src={airtel} className="provider_img"></img>
                  <img src={mobile} className="provider_img"></img>
                </div>
              </div>
              <input
                className="airtime_input"
                placeholder="Enter mobile Number"
              ></input>
              <input
                className="airtime_input"
                placeholder="Enter Amount"
              ></input>
              <div className="save_beneficiary">
                <label class="switch">
                  <input type="checkbox" />
                  <span class="slider round"></span>
                </label>
                <span className="beneficiary_label">Save Beneficiary</span>
              </div>
              <button className="airtime_btn">Purchase</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Airtime;
