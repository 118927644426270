import React from "react";
import PaymentProcessLogo from "../../assets/images/svg/payment_processing.svg";

function WhatWeDo() {
  return (
    <>
      <section className="what__we__do__page">
        <div className="what__we__do__heading text-center">
          <span>What We Do</span>
          <div className="what__we__do">
            <div className="single__what__we__do">
              <img src={PaymentProcessLogo} alt="Payment processing" />
              <br />
              <br />
              <span className="single__what__we__do__label">
                Payment processing
              </span>
              <p className="single__what__we__do__desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat
                nulla suspendisse tortor aene.
              </p>
            </div>
            <div className="single__what__we__do">
              <img src={PaymentProcessLogo} alt="Payment processing" />
              <br />
              <br />
              <span className="single__what__we__do__label">
                Merchant collections
              </span>
              <p className="single__what__we__do__desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat
                nulla suspendisse tortor aene.
              </p>
            </div>
            <div className="single__what__we__do">
              <img src={PaymentProcessLogo} alt="Payment processing" />
              <br />
              <br />
              <span className="single__what__we__do__label">
                igr collections
              </span>
              <p className="single__what__we__do__desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat
                nulla suspendisse tortor aene.
              </p>
            </div>
            <div className="single__what__we__do">
              <img src={PaymentProcessLogo} alt="Payment processing" />
              <br />
              <br />
              <span className="single__what__we__do__label">disbursement</span>
              <p className="single__what__we__do__desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat
                nulla suspendisse tortor aene.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WhatWeDo;
