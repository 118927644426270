import React from "react";
import search_booking from "../../assets/images/Icon/search_booking.png"; // Ensure this path is correct
import "./SearchResults.css"; // Adjust the path as necessary
const SearchResults = ({ searchResults }) => {
  return (
    <div className="bookings_content">
      {searchResults.map((result, index) => (
        <div key={index} className="search_result_container">
          <div className="airline_logo_container">
            <img
              src={search_booking}
              alt="Airline Logo"
              style={{ width: "32px", height: "32px" }}
            />
            <div>{result.airline}</div>
          </div>
          <div className="time_info_container">
            <div className="time_info">
              <strong>{result.departureTime}</strong>
              <div>{result.departureCode}</div>
            </div>
            <div>
              <div className="nonstop_line"></div>
              <div className="nonstop_info">nonstop</div>
            </div>
            <div className="time_info">
              <strong>{result.arrivalTime}</strong>
              <div>{result.arrivalCode}</div>
            </div>
            <div className="vertical_divider"></div>
          </div>
          <div className="economy_info_container">
            <div className="economy_label">
              <strong>Economy</strong>
            </div>
            <div>
              <strong>{result.price}</strong>
            </div>
            <button className="bookings_flight_btn">Book Flight</button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SearchResults;
