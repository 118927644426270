import React from "react";
import DownloadApplication from "../../assets/images/svg/download__apps.svg";

const DownloadApp = () => {
  return (
    <>
      <section className="download__app">
        <img src={DownloadApplication} alt="Download Application" />
      </section>
    </>
  );
};

export default DownloadApp;
