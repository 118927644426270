import React from "react";
import mtn from "../../assets/images/Icon/mtn.png";
import glo from "../../assets/images/Icon/glo.png";
import airtel from "../../assets/images/Icon/airtel.png";
import mobile from "../../assets/images/Icon/mobile.png";

import { Link } from "react-router-dom";
import { Breadcrumbs } from "../../AbstractElements";

const Settings = () => {
  return (
    <>
      <Breadcrumbs mainTitle="Settings" parent="Setting" title="Data" />
      <div className="row">
        <div className="col-2"></div>
      </div>
    </>
  );
};

export default Settings;
