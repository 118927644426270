import React from "react";
import "../LandingPage/LandingPage.css";
import appStoreLogo from "../../assets/images/svg/app-store.svg";
const AppStore = () => {
  return (
    <>
      <div className="store__button">
        <img src={appStoreLogo} alt="App-Store" />
        <div className="">
          <span className="info__line">GET IT ON</span> <br />
          <span className="store__line">App Store</span>
        </div>
      </div>
    </>
  );
};

export default AppStore;
