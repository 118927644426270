import React from "react";
import PaymentProcessLogo from "../../assets/images/svg/payment_processing.svg";

const MoreFeatures = () => {
  return (
    <>
      <section className="more__features">
        <div className="text-center">
          <span className="more__features__heading">MORE FEATURES</span>
          <h1>We Offer the Best in Maintenance and Support</h1>
        </div>
        <div className="features">
          <div className="single__feature">
            <img src={PaymentProcessLogo} alt="Payment processing" />
            <br />
            <br />
            <span className="single__feature__label">
              24/7 Help and Support
            </span>
            <p className="single__feature__desc">
              Resident Fintech Limited is a FinTech Firm with focus on payment
              services and IT consultancy.
            </p>
            <span className="learn__more">Learn More &#62;</span>
          </div>
          <div className="single__feature">
            <img src={PaymentProcessLogo} alt="Payment processing" />
            <br />
            <br />
            <span className="single__feature__label">
              Faster Transaction Times
            </span>
            <p className="single__feature__desc">
              Resident Fintech Limited is a FinTech Firm with focus on payment
              services and IT consultancy.
            </p>
            <span className="learn__more">Learn More &#62;</span>
          </div>
          <div className="single__feature">
            <img src={PaymentProcessLogo} alt="Payment processing" />
            <br />
            <br />
            <span className="single__feature__label">Regular Maintenance</span>
            <p className="single__feature__desc">
              Resident Fintech Limited is a FinTech Firm with focus on payment
              services and IT consultancy.
            </p>
            <span className="learn__more">Learn More &#62;</span>
          </div>
          <div className="single__feature">
            <img src={PaymentProcessLogo} alt="Payment processing" />
            <br />
            <br />
            <span className="single__feature__label">Privacy and Security</span>
            <p className="single__feature__desc">
              Resident Fintech Limited is a FinTech Firm with focus on payment
              services and IT consultancy.
            </p>
            <span className="learn__more">Learn More &#62;</span>
          </div>
        </div>
        <div className="get__started">
          <button>Get Started</button>
        </div>
      </section>
    </>
  );
};

export default MoreFeatures;
