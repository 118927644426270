import React, { useState } from "react";
import FaceBook from "../../src/assets/images/svg/Vector (1).svg";
import Twitter from "../../src/assets/images/svg/Vector (2).svg";
import LinkedIn from "../../src/assets/images/svg/Vector.svg";
import YouTube from "../../src/assets/images/svg/youtube.svg";
import Email from "../../src/assets/images/svg/iconamoon_email.svg";
import Phone from "../../src/assets/images/svg/lucide_phone.svg";
import Building from "../../src/assets/images/svg/mingcute_building-1-line.svg";
import Apple from "../assets/images/svg/apple.svg";
import PlayStore from "../assets/images/svg/playstore.svg";
import "./Footer.css";
import { H5 } from "../AbstractElements";
const Footer = () => {
  const [showMap, setShowMap] = useState(false);
  const iconStyle = {
    width: "20px",
    height: "20px",
  };
  return (
    <>
      <section className="container-fluid footer">
        <div className="row">
          <div className="col-8">
            <div className="row">
              <div className="col-3">
                <div className="info__section p-5">
                  <ul>
                    <li>
                      {" "}
                      <H5>Company Info</H5>
                    </li>
                    <li>About</li>
                    <li>Services</li>
                    <li>Merchant</li>
                    <li>Vendors</li>
                    <li>Affiliate Marketing</li>
                  </ul>
                </div>
              </div>
              <div className="col-2">
                <div className="legal__section p-5">
                  <ul>
                    <li>
                      {" "}
                      <H5>Legal</H5>
                    </li>
                    <li>Policy</li>
                    <li>Term</li>
                    <li>Cookies</li>
                    <li>Licenses</li>
                  </ul>
                </div>
              </div>
              <div className="col-2">
                <div className="social__section p-5">
                  <ul>
                    <li>
                      <H5>Social</H5>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/YourPage"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={FaceBook} alt="Facebook" style={iconStyle} />
                      </a>
                      Facebook
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/YourProfile"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={Twitter} alt="Twitter" style={iconStyle} />
                      </a>
                      Twitter
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/YourProfile"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={LinkedIn} alt="LinkedIn" style={iconStyle} />
                      </a>
                      LinkedIn
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/c/YourChannel"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={YouTube} alt="YouTube" style={iconStyle} />
                      </a>
                      YouTube
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-4">
                <div className="get__in__touch__section p-5">
                  <ul>
                    <li>
                      <H5>Get In Touch</H5>
                    </li>
                    <li
                      onClick={() => setShowMap(!showMap)}
                      style={{ cursor: "pointer" }}
                    >
                      <img src={Building} alt="Building" />
                      No 38 Mike Ahkigbe Way Jabi Abuja, Nigeria
                    </li>
                    <li>
                      <img src={Phone} alt="Phone" /> +234 805 555 7778
                    </li>
                    <li>
                      <img src={Email} alt="Email" /> info@residentfintech.com
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="download__from__footer mt-5">
              <h2>Download our Application</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                sed nulla integer
              </p>
              <div className="download__cta">
                {/* Wrap the button with an <a> tag for the App Store */}
                <a
                  href="https://apps.apple.com/app/your-app-id"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <button
                    className="btn"
                    style={{ display: "inline-flex", alignItems: "center" }}
                  >
                    <img
                      src={Apple}
                      alt="App Store"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                    />
                    App Store
                  </button>
                </a>
                {/* Wrap the button with an <a> tag for the Play Store */}
                <a
                  href="https://play.google.com/store/apps/details?id=your.package.name"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <button
                    className="btn"
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      marginLeft: "10px",
                    }}
                  >
                    <img
                      src={PlayStore}
                      alt="Play Store"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                      }}
                    />
                    PLAY STORE
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        {showMap && (
          <div className="row">
            <div className="map__section mt-4">
              <iframe
                title="Company Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3939.947082090931!2d7.424598314793033!3d9.07647949348566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104d34f3c7a8a7d7%3A0xa8d3701e3b8a6a6!2s38%20Mike%20Akhigbe%20Way%2C%20Jabi%2C%20Abuja!5e0!3m2!1sen!2sng!4v1642619389546!"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        )}
        <div className="social__icons">
          <a
            href="https://www.linkedin.com/yourcompany"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={LinkedIn} alt="LinkedIn" style={iconStyle} />
          </a>
          <a
            href="https://twitter.com/yourcompany"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Twitter} alt="Twitter" style={iconStyle} />
          </a>
          <a
            href="https://www.facebook.com/yourcompany"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={FaceBook} alt="Facebook" style={iconStyle} />
          </a>
        </div>
        <hr className="line" />
        <div className="all__right__reserved d-flex justify-content-between py-3">
          <div className="rights">
            <span>All rights reserved</span>
          </div>
          <div className="terms__privacy__cookies">
            <span>Terms</span>
            <span>Privacy</span>
            <span>Cookies</span>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
