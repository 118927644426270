import React from "react";
import JoinUsImage from "../../assets/images/svg/join-us.svg";

const JoinUs = () => {
  return (
    <>
      <section className="join__us">
        <div className="row">
          {/* <div className="col-2"></div> */}
          <div className="col-5">
            <div
              className="image__section"
              style={{ display: "flex", justifyContent: "right" }}
            >
              <img src={JoinUsImage} alt="Join US" />
            </div>
          </div>
          <div className="col-5">
            <div className="mail__section ps-5">
              <h2>JOIN OUR MAILING LIST</h2>
              <p>Be the first to get our announcements and updates</p>
              <input type="text" placeholder="Email Address" />
              <div className="join__us__cta">
                <button className="btn">Join US</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JoinUs;
