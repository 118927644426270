import React, { useState } from "react";
import search_booking from "../../assets/images/Icon/search_booking.png";
import glo from "../../assets/images/Icon/glo.png";
import airtel from "../../assets/images/Icon/airtel.png";
import mobile from "../../assets/images/Icon/mobile.png";

import { Link } from "react-router-dom";
import "./Bookings.css";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import SplitButton from "react-bootstrap/SplitButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane, faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import SearchResults from "./SearchResults";
import { Form } from "react-bootstrap"; // Make sure Form is imported from 'react-bootstrap'
import { Breadcrumbs } from "../../AbstractElements";
const Bookings = () => {
  const today = new Date().toISOString().split("T")[0];

  const fakeData = [
    {
      id: 1,
      airline: "Turkish Airlines",
      departureTime: "8:50 AM",
      departureCode: "ABV",
      arrivalTime: "2:30 PM",
      arrivalCode: "IST",
      price: "NGN 1,200,000",
    },
    {
      id: 2,
      airline: "British Airways",
      departureTime: "10:00 AM",
      departureCode: "LHR",
      arrivalTime: "4:00 PM",
      arrivalCode: "JFK",
      price: "NGN 1,500,000",
    },
    // Add more entries as needed
    {
      id: 3,
      airline: "Delta Airlines",
      departureTime: "9:30 AM",
      departureCode: "ATL",
      arrivalTime: "3:45 PM",
      arrivalCode: "LAX",
      price: "NGN 1,800,000",
    },
    {
      id: 4,
      airline: "Qatar Airways",
      departureTime: "11:00 PM",
      departureCode: "DOH",
      arrivalTime: "5:00 AM",
      arrivalCode: "CDG",
      price: "NGN 2,000,000",
    },
    {
      id: 5,
      airline: "Emirates",
      departureTime: "1:00 AM",
      departureCode: "DXB",
      arrivalTime: "6:00 AM",
      arrivalCode: "SYD",
      price: "NGN 2,500,000",
    },
    {
      id: 6,
      airline: "Lufthansa",
      departureTime: "7:00 AM",
      departureCode: "FRA",
      arrivalTime: "9:00 AM",
      arrivalCode: "MAD",
      price: "NGN 1,100,000",
    },
    {
      id: 7,
      airline: "KLM",
      departureTime: "5:00 PM",
      departureCode: "AMS",
      arrivalTime: "8:00 PM",
      arrivalCode: "NRT",
      price: "NGN 2,200,000",
    },
    {
      id: 8,
      airline: "Air France",
      departureTime: "2:00 PM",
      departureCode: "CDG",
      arrivalTime: "4:00 PM",
      arrivalCode: "SFO",
      price: "NGN 2,800,000",
    },
  ];
  const locations = ["New York", "Los Angeles", "Chicago", "Houston"];
  const [selectedFrom, setSelectedFrom] = useState(locations[0]);
  const [selectedDestination, setSelectedDestination] = useState(locations[2]);
  const handleFromChange = (e) => setSelectedFrom(e.target.value);
  const handleDestinationChange = (e) => setSelectedDestination(e.target.value);
  const [showSearch, setShowSearch] = useState(false);
  const [tripType, setTripType] = useState("One-way");
  const [passengers, setPassengers] = useState("1 Adult");
  const [classType, setClassType] = useState("Economy");
  // Handling changes for each select
  const handleTripTypeChange = (e) => setTripType(e.target.value);
  const handlePassengersChange = (e) => setPassengers(e.target.value);
  const handleClassTypeChange = (e) => setClassType(e.target.value);

  const handleSwapLocations = () => {
    // Temporarily store the current 'From' value
    const currentFrom = selectedFrom;
    // Swap the values directly
    setSelectedFrom(selectedDestination);
    setSelectedDestination(currentFrom);
  };
  return (
    <>
      <Breadcrumbs
        mainTitle="Flight Bookings"
        parent="Book Flight"
        title="Search"
      />
      <div className="row">
        {showSearch ? (
          <SearchResults searchResults={fakeData} />
        ) : (
          <div className="bookings_content">
            <div className="row">
              <div className="buy_bookings_content">
                <div className="dropdown-container">
                  <Form>
                    <div className="row">
                      {/* Trip Type */}
                      <div className="col-md-4">
                        <Form.Group controlId="formTripType">
                          <Form.Select
                            value={tripType}
                            onChange={handleTripTypeChange}
                          >
                            <option>One-way</option>
                            <option>Round-trip</option>
                          </Form.Select>
                        </Form.Group>
                      </div>

                      {/* Passengers */}
                      <div className="col-md-4">
                        <Form.Group controlId="formPassengers">
                          <Form.Select
                            value={passengers}
                            onChange={handlePassengersChange}
                          >
                            <option>1 Adult</option>
                            <option>2 Adults</option>
                            <option>3 Adults</option>
                            {/* Additional options can be added here */}
                          </Form.Select>
                        </Form.Group>
                      </div>

                      {/* Class Type */}
                      <div className="col-md-4">
                        <Form.Group controlId="formClassType">
                          <Form.Select
                            value={classType}
                            onChange={handleClassTypeChange}
                          >
                            <option>Economy</option>
                            <option>Business</option>
                            <option>First Class</option>
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </div>
                  </Form>
                </div>

                <div className="input-exchange-container">
                  <div>
                    <Form>
                      <Form.Group
                        controlId="fromLocationSelect"
                        className="mb-3"
                      >
                        <Form.Select
                          value={selectedFrom}
                          onChange={handleFromChange}
                          className="bookings_input"
                        >
                          {locations.map((location, index) => (
                            <option key={index} value={location}>
                              {location}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group
                        controlId="destinationSelect"
                        className="mb-3"
                      >
                        <Form.Select
                          value={selectedDestination}
                          onChange={handleDestinationChange}
                          className="bookings_input"
                        >
                          {locations.map((location, index) => (
                            <option key={index} value={location}>
                              {location}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Form>
                  </div>
                  <div>
                    <button
                      className="exchange_icon_button"
                      onClick={handleSwapLocations}
                    >
                      <FontAwesomeIcon icon={faExchangeAlt} />
                    </button>
                  </div>
                </div>
                <div className="custom-date-icon">
                  <input
                    type="date"
                    className="form-control date-input-width"
                    defaultValue={today} // Assuming 'today' is defined
                  />
                  <i class="far fa-calendar-alt"></i>
                </div>

                <button
                  className="bookings_btn"
                  onClick={() => setShowSearch(true)}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Bookings;
