import React from "react";
import Airtime_Data from "../../assets/images/Icon/Airtime_Data.png";
import CableTV from "../../assets/images/Icon/CableTV.png";
import Merchant from "../../assets/images/Icon/Merchant.png";
import FlightBooking from "../../assets/images/Icon/FlightBooking.png";
import Shopping from "../../assets/images/Icon/Shopping.png";
import BettingandLottery from "../../assets/images/Icon/BettingandLottery.png";
import Utilities from "../../assets/images/Icon/Utilities.png";
import "./Bill.css"; // Make sure your CSS is properly linked
import { Breadcrumbs } from "../../AbstractElements";

const Bill = () => {
  return (
    <>
      <Breadcrumbs
        mainTitle="Bill Payment"
        parent="Bill Payment"
        title="Table"
      />
      <div className="row">
        <div className="bill_content">
          <div className="row">
            {/* Separate header div */}

            {/* Grid layout for bill containers starts here */}
            <div
              className="buy_bill_content"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
                gap: "20px",
              }}
            >
              {/* Bill container divs */}
              <div className="bill_container">
                <div className="icon_container">
                  <img
                    src={Airtime_Data}
                    alt="Airtime and Data"
                    className="bill_icon_img"
                  />
                </div>
                <div className="bill_icon_text">Airtime and data</div>
              </div>
              <div className="bill_container">
                <div className="icon_container">
                  <img src={CableTV} alt="Cable TV" className="bill_icon_img" />
                </div>
                <div className="bill_icon_text">Cable TV</div>
              </div>
              <div className="bill_container">
                <div className="icon_container">
                  <img
                    src={Merchant}
                    alt="Merchant Services"
                    className="bill_icon_img"
                  />
                </div>
                <div className="bill_icon_text">Merchant Services</div>
              </div>
              <div className="bill_container">
                <div className="icon_container">
                  <img
                    src={FlightBooking}
                    alt="Flights Booking"
                    className="bill_icon_img"
                  />
                </div>
                <div className="bill_icon_text">Flights Booking</div>
              </div>
              <div className="bill_container">
                <div className="icon_container">
                  <img
                    src={Shopping}
                    alt="Shopping"
                    className="bill_icon_img"
                  />
                </div>
                <div className="bill_icon_text">Shopping</div>
              </div>
              <div className="bill_container">
                <div className="icon_container">
                  <img
                    src={BettingandLottery}
                    alt="Betting and Lottery"
                    className="bill_icon_img"
                  />
                </div>
                <div className="bill_icon_text">Betting and Lottery</div>
              </div>
              <div className="bill_container">
                <div className="icon_container">
                  <img
                    src={Utilities}
                    alt="Utilities"
                    className="bill_icon_img"
                  />
                </div>
                <div className="bill_icon_text">Utilities</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bill;
