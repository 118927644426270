import React, { useContext, useState } from "react";
import { ArrowRight, ArrowLeft } from "react-feather";
import CustomizerContext from "../../_helper/Customizer";
import SidebarMenuItems from "./SidebarMenuItems";
import dashboard from "../../assets/images/Icon/dashboard.png";
import airtime from "../../assets/images/Icon/airtime.png";
import flight from "../../assets/images/Icon/flight.png";
import bill from "../../assets/images/Icon/bill.png";
import cable from "../../assets/images/Icon/cable.png";
import setting from "../../assets/images/Icon/setting.png";
import security from "../../assets/images/Icon/security.png";
import logout from "../../assets/images/Icon/logout.png";
import user from "../../assets/images/Icon/pngegg.png";
import "./Nav.css";
import { Link } from "react-router-dom";
import { H6 } from "../../AbstractElements";
import SvgIcon from "../../Components/Common/Component/SvgIcon";
const SidebarMenu = ({
  setMainMenu,
  props,
  sidebartoogle,
  setNavActive,
  activeClass,
  width,
}) => {
  const { customizer } = useContext(CustomizerContext);
  const wrapper = customizer.settings.sidebar.type;
  const [margin, setMargin] = useState(0);

  const scrollToRight = () => {
    if (margin <= -2598 || margin <= -2034) {
      if (width === 492) {
        setMargin(-3570);
      } else {
        setMargin(-3464);
      }
      document.querySelector(".right-arrow").classList.add("d-none");
      document.querySelector(".left-arrow").classList.remove("d-none");
    } else {
      setMargin((margin) => (margin += -width));
      document.querySelector(".left-arrow").classList.remove("d-none");
    }
  };

  const scrollToLeft = () => {
    if (margin >= -width) {
      setMargin(0);
      document.querySelector(".left-arrow").classList.add("d-none");
      document.querySelector(".right-arrow").classList.remove("d-none");
    } else {
      setMargin((margin) => (margin += width));
      document.querySelector(".right-arrow").classList.remove("d-none");
    }
  };

  return (
    <nav className="sidebar-main" id="sidebar-main">
      <div className="left-arrow" onClick={scrollToLeft}>
        <ArrowLeft />
      </div>
      <div
        id="sidebar-menu"
        style={
          wrapper.split(" ").includes("horizontal-wrapper")
            ? { marginLeft: margin + "px" }
            : { margin: "0px" }
        }
      >
        <div className="nav_container">
          <Link to="/dashboard">
            <div className="nav_button">
              <img src={dashboard} className="nav_button_img"></img>
              <div className="nav_button_txt">Dashboard</div>
            </div>
          </Link>
          <Link to="/airtime">
            <div className="nav_button">
              <img src={airtime} className="nav_button_img"></img>
              <div className="nav_button_txt">Buy Airtime & Data</div>
            </div>
          </Link>
          <Link to="/flight">
            <div className="nav_button">
              <img src={flight} className="nav_button_img"></img>
              <div className="nav_button_txt">Flight Bookings</div>
            </div>
          </Link>
          <Link to="/bill">
            <div className="nav_button">
              <img src={bill} className="nav_button_img"></img>
              <div className="nav_button_txt">Bill Payment</div>
            </div>
          </Link>
          <Link to="/cable">
            <div className="nav_button">
              <img src={cable} className="nav_button_img"></img>
              <div className="nav_button_txt">Pay Cable TV </div>
            </div>
          </Link>
          <div className="divider1"></div>
          <Link to="/account">
            <div className="nav_button">
              <img src={user} className="nav_button_img"></img>
              <div className="nav_button_txt">Account </div>
            </div>
          </Link>
          <Link to="/setting">
            <div className="nav_button">
              <img src={setting} className="nav_button_img"></img>
              <div className="nav_button_txt">Setting </div>
            </div>
          </Link>
          <Link to="/security">
            <div className="nav_button">
              <img src={security} className="nav_button_img"></img>
              <div className="nav_button_txt">Security</div>
            </div>
          </Link>
          <div className="divider2"></div>

          <Link to="/logout">
            <div className="nav_button">
              <img src={logout} className="nav_button_img"></img>
              <div className="nav_button_txt">Logout</div>
            </div>
          </Link>
        </div>
      </div>
      <div className="right-arrow" onClick={scrollToRight}>
        <ArrowRight />
      </div>
    </nav>
  );
};

export default SidebarMenu;
