import React, { useEffect, useState } from "react";
import _ from "lodash";
import Context from "./index";

import { getProfileAPI } from "../../Library/apis";
const UserProvider = (props) => {
  const [userInfo, setUserInfo] = useState({
    _id: "",
    userName: "",
    userId: "",
    profileImageUrl: "",
    firstName: "",
    lastName: "",
    middleName: "",
    email: "",
    role: "",
  });

  useEffect(() => {
    updateUserInfo();
  }, [userInfo]);

  const updateUserInfo = () => {
    getProfileAPI()
      .then((res) => {
        if (!_.isEqual(res.body, userInfo)) {
          setUserInfo(res.body);
        }
      })
      .catch((e) => console.log(e));
  };

  const [isLoading, setIsLoading] = useState(false);

  return (
    <Context.Provider
      value={{
        ...props,
        userInfo,
        setUserInfo: setUserInfo,
        updateUserInfo: updateUserInfo,
        isLoading,
        setIsLoading,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default UserProvider;
