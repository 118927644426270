import React from "react";
import AboutUs from "../../assets/images/svg/about-us.png";

const About = () => {
  return (
    <>
      <section className="bg-body-tertiary about__us__page">
        <div className="row">
          <div className="col-6">
            <div className="about__us">
              <span>ABOUT US</span>
              <h1>Who We Are</h1>
              <p>
                Resident Fintech Limited is a FinTech Firm with focus on payment
                services and IT consultancy. The company deals in the processing
                of all electronic payment transactions using its in-house
                developed solution. The company also carries on business of
                Information and communication technology (ICT) services.
              </p>
              <p className="learn__more">Learn More &#62;</p>
            </div>
          </div>
          <div className="col-4 p-3">
            <img src={AboutUs} alt="About Us" />
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
