import React from "react";
import "../LandingPage/LandingPage.css";
import GoogleStore from "../LandingPage/GoogleStore";
import AppStore from "../LandingPage/AppStore";
import ellise31 from "../../assets/images/svg/Ellipse 31.svg";
import ellise32 from "../../assets/images/svg/Ellipse 32.svg";
import ellise28 from "../../assets/images/svg/Ellipse 28.svg";
import image11 from "../../assets/images/svg/image 11.svg";
import image13 from "../../assets/images/svg/image 13.svg";
import image14 from "../../assets/images/svg/image 14.svg";
import image12 from "../../assets/images/svg/image 12.svg";

const Discover = () => {
  return (
    <>
      <section className="discover">
        <div className="row">
          <div className="col-6">
            <div>
              <h1 className="discover__heading">
                Discover the <br />
                <span className="hightlight__text">Hub</span>&nbsp;For&nbsp;
                <span className="hightlight__text">
                  Financial <br /> Solutions
                </span>
              </h1>
              <p className="discover__description py-5">
                Resident Fintech technology solutions provides you with the
                tools for <br /> financial freedom
              </p>
              <div className="sore__section d-flex">
                {/* <div className="row"> */}
                {/* <div className="col-6"> */}
                <GoogleStore />
                {/* </div> */}
                {/* <div className="col-6"> */}
                <AppStore />
                {/* </div>   */}
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row justify-content-end">
              {/* <div className="col-2 ellipse31">
                <img src={ellise31} alt="Ellipse" />
              </div> */}
              <div className="col-5 text-center">
                <div className="image__ellipse">
                  <img className="ellipse28" src={ellise28} alt="Ellipse" />
                  <img src={image11} alt="" />
                </div>
              </div>
              <div className="col-5">
                {/* <img className="ps-5 ms-5" src={ellise31} alt="ellise31" /> */}
                <img src={image13} alt="image13" />
              </div>
            </div>
            <div className="row second__row justify-content-between">
              <div className="col-2 ellise32">
                <img src={ellise32} alt="Ellipse" />
              </div>
              <div className="col-5 text-center">
                <div className="image__ellipse">
                  <img src={image14} alt="Image 11" />
                </div>
              </div>
              <div className="col-5">
                <img src={image12} alt="" />
              </div>
            </div>
            <div className="row">
              <img className="bottom__ellipse" src={ellise32} alt="Ellipse" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Discover;
