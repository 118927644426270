import { Settings } from "react-feather";
import Airtime from "../Components/Airtime/Airtime";
import Bill from "../Components/Bill/Bill";
import Bookings from "../Components/Bookings/Bookings";
import Dashboard from "../Components/Dashboard/Dashboard";
import LandingPage from "../Components/LandingPage/LandingPage";
import PayCableTV from "../Components/PayCableTV/PayCableTV";
import Register from "../Components/Auth/Register/Register";
import Login from "../Components/Auth/Login/Login";
import Security from "../Components/Security/Security";
import CodeVerification from "../Components/Auth/CodeVerification";
import ForgetPwdMain from "../Components/Auth/ForgotPassword";
export const authRoutes = [
  { path: `/home`, Component: <LandingPage /> },
  {
    path: `/auth/register`,
    Component: <Register />,
  },
  { path: `/auth/login`, Component: <Login /> },
  { path: "/auth/code-verification", Component: <CodeVerification /> },
  { path: "/auth/forget-pwd", Component: <ForgetPwdMain /> },
];
