import React, { useEffect, useState } from "react";
import logo from "../assets/favicon.png";
import "./Navbar.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    // Attach the event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarClasses = `navbar navbar-expand-lg ${
    scrolled ? "sticky-header scrolled" : ""
  }`;

  return (
    <>
      <nav className={navbarClasses}>
        <div className="container-fluid main__header">
          <a className="navbar-brand" href="#">
            <img
              src={logo}
              alt="Resident Fintech"
              style={{ width: "30px", height: "30px" }}
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link"
                  activeClassName="active"
                  aria-current="page"
                  href="#"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  About
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Merchant
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Vendors
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Affiliate Marketing
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Contact
                </a>
              </li>
            </ul>
            <div className="auth__facility">
              <div className="sign__in scrolled">
                <Link to="/auth/login">Sign In</Link>
              </div>
              <div className="create__account">
                <Link to="/auth/register">Create free account</Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
