import React, { Fragment } from "react";
import { Col, Container, Row } from "reactstrap";
import { Breadcrumbs } from "../../AbstractElements";
import EditMyProfile from "./EditmyProfile";
import MyProfileEdit from "./MyProfile";
import UserTable from "./UserTable";

const Account = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Account" parent="Account" title="Edit Profile" />
      <Container fluid={true}>
        <div className="edit-profile">
          <Row>
            <Col xl="4">
              <MyProfileEdit />
            </Col>
            <Col xl="8">
              <EditMyProfile />
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};
export default Account;
