import Dashboard from "../Components/Dashboard/Dashboard";
import Airtime from "../Components/Airtime/Airtime";
import PrivateRoute from "../Route/PrivateRoute";
import React, { useState, useEffect } from "react";
import Bookings from "../Components/Bookings/Bookings";
import Bill from "../Components/Bill/Bill";
import PayCableTV from "../Components/PayCableTV/PayCableTV";
import Settings from "../Components/Settings/Settings";
import Security from "../Components/Security/Security";
import Logout from "../Components/Auth/Logout";
import Account from "../Components/Account";
export const routes = [
  { path: `/dashboard`, Component: <Dashboard /> },
  { path: `/airtime`, Component: <Airtime /> },
  { path: `/flight`, Component: <Bookings /> },
  { path: `/bill`, Component: <Bill /> },
  { path: `/cable`, Component: <PayCableTV /> },
  { path: `/account`, Component: <Account /> },
  { path: `/setting`, Component: <Settings /> },
  { path: `/security`, Component: <Security /> },
  { path: `/logout`, Component: <Logout /> },
];
