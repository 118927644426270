import React from "react";
import googleStoreLogo from "../../assets/images/svg/google-play-store.svg";

const GoogleStore = () => {
  return (
    <>
      <div className="store__button">
        <img src={googleStoreLogo} alt="Gpogle-Store" />
        <div className="">
          <span className="info__line">GET IT ON</span> <br />
          <span className="store__line">Google Play</span>
        </div>
      </div>
    </>
  );
};

export default GoogleStore;
