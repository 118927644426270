import React from "react";
import { Fragment } from "react";
import { Col, Container, Row } from "reactstrap";
import { H6 } from "../../AbstractElements";

const Footer = () => {
  return (
    <Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md="12" className="footer-copyright text-center">
              <H6
                attrPara={{
                  className: "mb-0",
                  style: { color: "white" },
                }}
              >
                {"Copyright 2024 © Resident."}
              </H6>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
};

export default Footer;
