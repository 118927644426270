import React, { useState } from "react";
import AboutUs from "../../../assets/images/svg/about-us.png";
import ellise31 from "../../../assets/images/svg/Ellipse 31.svg";
import ellise32 from "../../../assets/images/svg/Ellipse 32.svg";
import ellise28 from "../../../assets/images/svg/Ellipse 28.svg";
import logo from "../../../assets/favicon.png";
import { signUpAPI } from "../../../Library/apis";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css";

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    agreeToPolicy: false,
  });
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    try {
      setLoading(true);
      const data = await signUpAPI(formData);
      console.log("SignUp Result: ", data);
      toast.success(`Sent Verificaton code.`, { autoClose: 2000 });

      navigate("/auth/code-verification", {
        state: { userInfo: formData.email },
      });
    } catch (error) {
      console.log(JSON.stringify(error?.response?.data));
      toast.error(error?.response?.data?.message || "An error occurred"); // Use toast.error for error message
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />{" "}
      {/* Render ToastContainer at the top level of your component */}
      <div className="row">
        <div className="login_logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="login_form_wrapper">
          <form className="login_form" onSubmit={handleSubmit}>
            <img src={ellise28} className="ellipse28" alt="logo" />
            <img src={ellise31} className="ellipse31" alt="logo" />
            <img src={ellise32} className="ellipse32" alt="logo" />

            <div className="login_header">
              <span className="hightlight__text">Welcome! </span>
              <span className="auth_title">Sign up to get started!</span>
            </div>
            <div className="row">
              <div className="col-6">
                <input
                  className="auth_input"
                  placeholder="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                />
              </div>
              <div className="col-6">
                <input
                  className="auth_input"
                  placeholder="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <input
              className="auth_input"
              placeholder="Email Address"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <input
              className="auth_input"
              placeholder="Mobile Number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
            <input
              className="auth_input"
              placeholder="Password"
              type={togglePassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            <input
              className="auth_input"
              placeholder="Confirm Password"
              type={togglePassword ? "text" : "password"}
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
            />
            <button className="auth_button" type="submit" disabled={loading}>
              {loading ? "Signing Up..." : "SIGN UP"}
            </button>
            <div className="guide_text">
              Already have an account?{" "}
              <span className="color_text">
                <Link to="/auth/login">Login</Link>
              </span>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Register;
