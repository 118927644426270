import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { loginAPI, sendVerificationRequestAPI } from "../../../Library/apis";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import ToastContainer and toast CSS

import logo from "../../../assets/favicon.png";
import ellise31 from "../../../assets/images/svg/Ellipse 31.svg";
import ellise32 from "../../../assets/images/svg/Ellipse 32.svg";
import ellise28 from "../../../assets/images/svg/Ellipse 28.svg";
import "./Login.css";
import { AuthContext } from "../../../App";
import { Form, FormGroup, Input, Label, Spinner } from "reactstrap";
import UserContext from "../../../_helper/User";
const Login = () => {
  const { setToken } = useContext(AuthContext);
  const [togglePassword, setTogglePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    userInfo: "",
    password: "",
  });
  const navigate = useNavigate();
  const { setUserInfo } = useContext(UserContext);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = await loginAPI(formData);
      data?.body?.userInfo && setUserInfo(data?.body?.userInfo);
      localStorage.setItem("authenticated", true);
      localStorage.setItem("login", true);
      localStorage.setItem("userInfo", JSON.stringify(data?.body?.userInfo));
      localStorage.setItem("token", data?.body?.token);

      navigate(`/dashboard`);
    } catch (error) {
      console.log(error);
      if (error?.response?.status == 402) {
        sendVerificationRequestAPI({ userInfo: formData.userInfo })
          .then(() => {
            toast.success("Verification code sent again.", { autoClose: 2000 });
            navigate("/auth/code-verification", {
              state: { userInfo: formData.userInfo },
            });
          })
          .catch((e) => console.log(e));
      }
      toast.error(error?.response?.data?.message, { autoClose: 2000 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />{" "}
      <div className="row">
        <div className="login_logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="login_form_wrapper">
          <form className="login_form" onSubmit={handleSubmit}>
            <img src={ellise28} className="ellipse28" alt="logo" />
            <img src={ellise31} className="ellipse31" alt="logo" />
            <img src={ellise32} className="ellipse32" alt="logo" />

            <div className="login_header">
              <span className="hightlight__text">Welcome! </span>
              <span className="auth_title">Log In to Continue</span>
            </div>

            <input
              className="auth_input"
              placeholder="Email Address or Phone Number"
              type="text"
              name="userInfo"
              value={formData.userInfo}
              onChange={handleChange}
            />

            <input
              className="auth_input"
              placeholder="Enter Password"
              type={togglePassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
            />

            <button className="auth_button" type="submit">
              {loading ? (
                <>
                  <Spinner size="sm" color="light" />
                  {" Loading..."}
                </>
              ) : (
                "LOG IN"
              )}
            </button>

            <div className="color_text guide_text">
              <Link to="/auth/forget-pwd">Forgot Password?</Link>
            </div>
            <div className="guide_text">
              Don't have an account?{" "}
              <span className="color_text">
                <Link to="/auth/register">Create free account</Link>
              </span>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
