// import React from "react";
// import Testimonial from "../../assets/images/testimonials.svg";

// const Testimonials = () => {
//   return (
//     <>
//       <section className="testimonials">
//         <div className="text-center">
//           <span className="testimonials__heading">TESTIMONIALS</span>
//           <h1>What Our Customers Say</h1>
//           <div className="feedback d-flex">
//             <div className="single__fedback">
//               <p>
//                 “Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
//                 commodo dolor fermentum dignissim et pellentesque egestas
//                 mauris, faucibus. Tellus nisi amet non at phasellus faucibus
//                 senectus in”
//               </p>
//               <div className="d-flex">
//                 <img src={Testimonial} alt="Testimonial" />
//                 <div className="ms-3">
//                   <span>John carter</span> <br />
//                   <span>Role, Company</span>
//                 </div>
//               </div>
//             </div>
//             <div className="single__fedback">
//               <p>
//                 “Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
//                 commodo dolor fermentum dignissim et pellentesque egestas
//                 mauris, faucibus. Tellus nisi amet non at phasellus faucibus
//                 senectus in”
//               </p>
//               <div className="d-flex">
//                 <img src={Testimonial} alt="Testimonial" />
//                 <div className="ms-3">
//                   <span>John carter</span> <br />
//                   <span>Role, Company</span>
//                 </div>
//               </div>
//             </div>
//             <div className="single__fedback">
//               <p>
//                 “Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
//                 commodo dolor fermentum dignissim et pellentesque egestas
//                 mauris, faucibus. Tellus nisi amet non at phasellus faucibus
//                 senectus in”
//               </p>
//               <div className="d-flex">
//                 <img src={Testimonial} alt="Testimonial" />
//                 <div className="ms-3">
//                   <span>John carter</span> <br />
//                   <span>Role, Company</span>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Testimonials;

import React from "react";
import Slider from "react-slick";
import TestimonialImage from "../../assets/images/svg/testimonials.svg";

const Testimonials = () => {
  const testimonialsData = [
    {
      content:
        "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. In commodo dolor fermentum dignissim et pellentesque egestas mauris, faucibus. Tellus nisi amet non at phasellus faucibus senectus in”",
      author: "John Carter",
      role: "Role, Company",
    },
    {
      content:
        "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. In commodo dolor fermentum dignissim et pellentesque egestas mauris, faucibus. Tellus nisi amet non at phasellus faucibus senectus in”",
      author: "John Carter",
      role: "Role, Company",
    },
    {
      content:
        "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. In commodo dolor fermentum dignissim et pellentesque egestas mauris, faucibus. Tellus nisi amet non at phasellus faucibus senectus in”",
      author: "John Carter",
      role: "Role, Company",
    },
    {
      content:
        "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. In commodo dolor fermentum dignissim et pellentesque egestas mauris, faucibus. Tellus nisi amet non at phasellus faucibus senectus in”",
      author: "John Carter",
      role: "Role, Company",
    },
    {
      content:
        "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. In commodo dolor fermentum dignissim et pellentesque egestas mauris, faucibus. Tellus nisi amet non at phasellus faucibus senectus in”",
      author: "John Carter",
      role: "Role, Company",
    },
    // Add more testimonial data as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
  };

  return (
    <section className="testimonials">
      <div className="text-center">
        <span className="testimonials__heading">TESTIMONIALS</span>
        <h1>What Our Customers Say</h1>
        <Slider {...settings}>
          {testimonialsData.map((testimonial, index) => (
            <div key={index} className="single__fedback">
              <p>{testimonial.content}</p>
              <div className="d-flex">
                <img src={TestimonialImage} alt="Testimonial" />
                <div className="ms-3">
                  <span>John carter</span> <br />
                  <span>Role, Company</span>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
