import { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
const Logout = () => {
  useEffect(() => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    localStorage.removeItem("authenticated");

    // Refresh the browser
    window.location.reload();
  }, []);
  return <Navigate to="/home" />;
};

export default Logout;
