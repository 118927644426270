import React from "react";
import Routers from "./Route";

import ProjectProvider from "./_helper/Project/ProjectProvider";

import ContactProvider from "./_helper/Contact/ContactProvider";
import TaskProvider from "./_helper/Task/TaskProvider";

import TodoProvider from "./_helper/Todo/TodoProvider";
import EmailProvider from "./_helper/Email/EmailProvider";
import SearchResultProvider from "./_helper/SearchResult/SearchResult";

import FilterProvider from "./_helper/Ecommerce/Filter/FilterProvider";

import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/Customizer/CustomizerProvider";
import UserProvider from "./_helper/User/UserProvider";
export const AuthContext = React.createContext("auth");

const App = () => (
  <div className="App">
    <CustomizerProvider>
      <SearchResultProvider>
        <EmailProvider>
          <TodoProvider>
            <TaskProvider>
              <ProjectProvider>
                <UserProvider>
                  <AnimationThemeProvider>
                    <Routers />
                  </AnimationThemeProvider>
                </UserProvider>
              </ProjectProvider>
            </TaskProvider>
          </TodoProvider>
        </EmailProvider>
      </SearchResultProvider>
    </CustomizerProvider>
  </div>
);

export default App;
